body{
	margin:0 auto;
	font-size:12px;
	background:url(../images/kiwihug-zGZYQQVmXw0-unsplash.jpg)no-repeat;
	background-size:cover;
	background-position:center;
}
h1,h2,h3,h4,h5,h6{
	margin:0 auto;
	padding:0;
}
.BannerSlider{
	margin-top:40px;
	max-width:800px;
}
.BannerSlider img{
	width:100%;
}
.subTitle{
	font-size:15px;
	color:#111;
	text-transform: uppercase;
	font-family: 'Roboto Mono', monospace;
}
@media screen and (min-width: 601px) {
    .headTitle{
        font-size:60px;
        color:#111;
        letter-spacing:5px;
        font-family: 'Bebas Neue', cursive;
        text-transform: uppercase;
    }
  }
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    .headTitle{
        font-size:30px;
        color:#111;
        letter-spacing:1px;
        font-family: 'Bebas Neue', cursive;
        text-transform: uppercase;
    }
  }
.productShadow{

}
.immages img{
	width:100%;
}
.produkName{
	font-family:Arial;
	font-size:16px;
	padding-left:5px;
	padding-right:5px;
}
.pricing{
	font-family:Arial;
	font-size:14px;
	padding-left:5px;
	padding-right:5px;
}
.pricingold{
	margin-top:10px;
	width:100%;
	font-family:Arial;
	font-size:11px;
	padding-left:5px;
	padding-right:5px;
	color:#FF0000;
	text-decoration: line-through;
}
.viewProduk{
	width:100%;
	padding:6px;
	background:#fff;
	border:1px solid #eaeaea;
	color:#111;
	margin-top:10px;
}
.box {
  position: relative;
}
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #6C3B74;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #DC143C;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
.popupTitle{
	font-size:20px;
}
.pricing2{
	font-size:20px;
}
.coret{
	text-decoration: line-through;
	font-size:12px;
	color:#FF8484;
}
.deskripsi2{
	margin-top:20px;
}
.rating i{
 color:#D0D0D0;
}
.rating i.checked{
 color:#DFCB00;
}
.ima2 div{
	height:350px;
}
.ima2 div img{
	width:100%;
	object-fit: cover;
}
.slider-nav { 
text-align: left; 
background:#EAEAEA;
overflow-x: auto;
display: flex;
flex-direction: row;
}
.slider-nav > div {
  display:inline-block;
  background: #EAEAEA; 
  cursor: pointer;
  padding:3px;
}
.slider-nav img{
	height:90px;
}


.slider-nav::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.slider-nav::-webkit-scrollbar
{
	height:5px;
	background-color: #F5F5F5;
}

.slider-nav::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #555555;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #eaeaea;
	border-radius: 12px;
}

::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #eaeaea;
	background-image: -webkit-linear-gradient(top,
											  #8B008B 50%,
											  #800080 100%);
}